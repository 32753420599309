<template>
    <div class="product-view">
        <bxs-top-nav>
            <span>Oggetto</span>

            <template #append>
                <bxs-chip
                v-if="item && item.loan && item.loan.status === 'busy'"
                x-small
                light
                color="primary"
                class="text-uppercase">In prestito</bxs-chip>
            </template>
        </bxs-top-nav>

        <bxs-data-lazy
        v-model="item"
        :call="() => $leila.catalogs.getProduct($route.params.id, this.$route.query)">
            <!--  -->
            <section>
                <bxs-layout>
                    <div class="mb-ui">
                        <div class="mb-ui">
                            <bxs-title v-html="item.name"></bxs-title>
                        </div>

                        <!-- figure -->
                        <div>
                            <div v-if="item.imgs.length > 0">
                                <bxs-carousel
                                :items="item.imgs"
                                item-width="100%"
                                :items-per-view="1"
                                :items-space-between="0"
                                :mousewheel="false"
                                navigation>
                                    <template #item="{ item }">
                                        <bxs-figure
                                        ratio="1"
                                        :src="$filters.toStaticFile(item)"
                                        class="mx-auto"></bxs-figure>
                                    </template>
                                </bxs-carousel>

                                <div>
                                    <small style="font-size: 10px !important;">L'immagine ha solo lo scopo di rappresentare il prodotto, quello presente in biblioteca potrebbe essere differente sia nel colore che nel modello.</small>
                                </div>
                            </div>

                            <bxs-figure
                            v-else
                            ratio="21/9"
                            src="/img/banner.jpg"
                            class="mx-auto"></bxs-figure>
                        </div>

                        <!-- desc -->
                        <div class="mt-ui">
                            <bxs-title
                            class="mb-ui"
                            style="font-size: 16px !important;">Descrizione</bxs-title>

                            <bxs-card class="pa-ui">
                                <div
                                v-if="item.description"
                                v-html="item.description"
                                class="wysiwyg"></div>

                                <div
                                v-if="item.links.instructions"
                                class="mt-ui text-right">
                                    <a
                                    :href="item.links.instructions"
                                    target="_blank"
                                    class="mb-0">Vedi istruzioni</a>
                                </div>

                                <div
                                v-if="item.links.instruction_manual"
                                class="mt-ui text-right">
                                    <a
                                    :href="item.links.instruction_manual"
                                    target="_blank"
                                    class="mb-0">Vedi manuale di istruzioni</a>
                                </div>
                            </bxs-card>
                        </div>
                    </div>

                    <!-- actions -->
                    <bxs-card class="pa-ui">
                        <!-- disponibile -->
                        <div v-if="item.loan.status === 'available'">
                            <bxs-title class="mb-ui">Punto di ritiro</bxs-title>

                            <headquarter-row
                            v-if="!!item.location.address"
                            title="Si trova in"
                            :item="item.location.address"
                            hide-opening-time
                            class="mb-ui" />

                            <bxs-btn
                            color="primary"
                            :disabled="add_to_cart.loading"
                            :loading="add_to_cart.loading"
                            light
                            min-height="60"
                            block
                            uppercase
                            @click="addToCart">Prendi in prestito</bxs-btn>
                        </div>

                        <!-- in manutenzione -->
                        <bxs-alert
                        v-if="item.loan.status === 'maintenance'"
                        type="info">Oggetto in manutenzione</bxs-alert>

                        <!-- in prestito -->
                        <bxs-alert
                        v-if="item.loan.status === 'busy'"
                        type="warn">Oggetto in prestito <b>fino al {{ $filters.toHumanDate(item.loan.to_date) }}</b></bxs-alert>

                        <bxs-btn
                        v-if="item.loan.status === 'busy' || item.loan.status === 'maintenance'"
                        :disabled="add_to_wish.loading"
                        min-height="60"
                        light
                        color="primary"
                        block
                        uppercase
                        class="mt-ui"
                        @click="addToWish">Avvisami quando rientra</bxs-btn>

                        <!-- actions errors -->
                        <bxs-alert
                        v-if="add_to_cart.error"
                        type="error"
                        class="mt-ui">{{ add_to_cart.error }}</bxs-alert>
                    </bxs-card>
                </bxs-layout>
            </section>

            <!--  -->
            <signin-section v-if="item.loan.status === 'available'" />

            <!-- help -->
            <help-section />

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            reviews sections
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <section v-if="reviews.length > 0">
                <bxs-layout>
                    <bxs-title class="mb-ui">Recensioni</bxs-title>

                    <ul>
                        <li
                        v-for="(rev, i) in reviews"
                        :key="rev.id"
                        class="mb-3 mb-0-last">
                            <div class="flex nowrap align-center mb-2">
                                <p class="mr-ui mb-0 text-500">{{ rev.user.first_name || rev.user.name.split(' ')[0] }}</p>

                                <p class="mr-ui mb-0 text-disabled">{{ $filters.toHumanDate(rev.created_at, 'L') }}</p>

                                <div style="margin-top: -5px;">
                                    <bxs-icon
                                    v-for="n in rev.rating"
                                    :key="n"
                                    width="15"
                                    height="15"
                                    name="star-fill"
                                    class="text-secondary mr-1" />
                                </div>
                            </div>

                            <p>{{ rev.message }}</p>

                            <hr
                            v-if="i < reviews.length - 1"
                            class="mt-3">
                        </li>
                    </ul>
                </bxs-layout>
            </section>

            <!-- categories -->
            <categories-2-section />

            <!-- checkoutbar -->
            <checkoutbar to="/checkout/loan" />
        </bxs-data-lazy>
    </div>
</template>

<script>
import { Pagination, Navigation } from 'swiper'

export default {
    name: 'product',
    data () {
        return {
            swiper_modules: [Pagination, Navigation],
            is_in_wish: false,
            wish_key: 0,

            headquarters: [],
            reviews: [],

            loading: false,
            add_to_cart: {
                error: null,
                loading: false
            },
            add_to_wish: {
                error: null,
                loading: false
            },
            item: null,
            modelConfig: {
                type: 'string',
                mask: 'DD/MM/YYYY hh:ss' // Uses 'iso' if missing
            },
            selected_date: {
                start: null,
                end: null
            },
            timezone: null
        }
    },
    computed: {
        is_available () {
            const uc = !!this.$leila.me && this.$leila.me.available_loans > 0
            const ic = !!this.item && (!this.item.loan.status || this.item.loan.status === 'available')
            return !!uc && !!ic
        }
    },
    created () {
        if (this.$leila.auth.me) {
            this.$leila.me.get()
        }

        this.$leila.catalogs.getProductReviews(this.$route.params.id).then((docs) => {
            this.reviews = docs
        })
    },
    methods: {
        async addToWish () {
            if (!this.$leila.auth.me) {
                return this.$router.push('/signin')
            }

            this.add_to_wish.loading = true
            this.add_to_wish.error = null

            try {
                await this.$leila.me.addProductToWish(this.item.id)
                this.is_in_wish = true
                // this.wish_key += 1
                this.$toast.success('Ti avviseremo quando l\'oggetto tornerà disponibile')
            } catch (err) {
                this.add_to_wish.error = 'Ops, qualcosa è andato storto, riprova più tardi!'
                throw new Error(this.add_to_wish.error)
            } finally {
                this.add_to_wish.loading = false
            }
        },
        async addToCart () {
            this.add_to_cart.loading = true
            this.add_to_cart.error = null

            try {
                await this.$leila.checkout.addToCart(this.item.id)
                this.$router.push('/checkout/loan')
            } catch (err) {
                // if (err.code && err.code === 'auth.token_not_found' ) {
                // }
                if (err.status_code && err.status_code === 401) {
                    return this.$router.push('/signin')
                }

                this.add_to_cart.error = err.message || err.code || err
                throw new Error(err.message || err.code || err)
            } finally {
                this.add_to_cart.loading = false
            }
        }
    }
}
</script>