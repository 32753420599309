<template>
    <div class="checkout-loan-product-view">
        <bxs-top-nav>
            <span>Prestito</span>

            <template #append>
                <bxs-menu icon>
                    <bxs-list>
                        <bxs-list-item
                        append-icon="trash"
                        spacer
                        @click="clearCart">
                            <span>Abbandona questo prestito</span>

                            <template #append-icon>
                                <path fill-rule="evenodd" d="M6.146 8.146a.5.5 0 0 1 .708 0L8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 0 1 0-.708"/>
                                <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
                            </template>
                        </bxs-list-item>
                    </bxs-list>
                </bxs-menu>
            </template>
        </bxs-top-nav>

        <!-- date picker -->
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col cols="3">
                        <bxs-figure
                        ratio="1"
                        :src="$filters.toStaticFile($leila.checkout.product.img.url)" />
                    </bxs-col>

                    <bxs-col cols="9">
                        <div class="pl-ui">
                            <div class="mb-ui">
                                <bxs-title>{{ $leila.checkout.product.name }}</bxs-title>
                                <h6 class="text-uppercase text-mute">Periodo di prestito</h6>
                            </div>

                            <div
                            v-if="$leila.checkout.shipping.address.id !== 'delivery'"
                            class="flex align-center">
                                <bxs-chip
                                width="160"
                                :loading="calendar_loading"
                                tile>{{ $filters.toHumanDate($leila.checkout.loan.from_date) }}</bxs-chip>

                                <bxs-icon
                                width="60"
                                name="chevron-right"></bxs-icon>

                                <bxs-chip
                                width="160"
                                :loading="calendar_loading"
                                tile>{{ $filters.toHumanDate($leila.checkout.loan.to_date) }}</bxs-chip>
                            </div>

                            <div v-else>
                                <bxs-alert>Il periodo di prestito verrà calcolato in base alla data della consegna</bxs-alert>

                                <div
                                v-if="$leila.checkout.shipping.address.address"
                                class="mt-ui">
                                    <p>Indirizzo di consegna: {{ shipping.model.address }}, {{ shipping.model.cap }} {{ shipping.model.city }} {{ shipping.model.province }}</p>
                                </div>
                            </div>
                        </div>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- pickup -->
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <bxs-title>Scegli il punto di ritiro</bxs-title>
                </div>

                <bxs-data-lazy
                v-model="headquarters"
                :call="() => $leila.get('v1/headquarters')">
                    <bxs-card class="pa-ui">
                        <ul>
                            <li
                            v-for="(item, i) in computed_headquarters"
                            :key="item.id">
                                <headquarter-row
                                :class="['mb-ui', 'bxs-pointer', { 'bxs-disabled': item.id === $leila.checkout.shipping.address.id }]"
                                :item="item"
                                @click="setPickup(item)">
                                    <bxs-alert
                                    v-if="item.id === 'delivery'"
                                    class="mb-ui">Direttamente a casa tua con un contribuito di {{ $store.state.library.delivery.price }} €. Trovi le istruzioni per il contibuito richiesto nella prossima schermata. <br> Ricordati, hai 10 minuti per concludere l'ordine con PayPal.</bxs-alert>
                                    <!-- <p v-if="item.id === 'delivery'">Direttamente a casa tua con un contribuito di 5,00 €. Le istruzioni per il contibuito richiesto nella prossima schermata.</p> -->

                                    <template #actions>
                                        <bxs-icon
                                        v-if="item.id === $leila.checkout.shipping.address.id"
                                        text-color="primary"
                                        width="20"
                                        height="20">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </bxs-icon>

                                        <bxs-icon
                                        v-else
                                        width="20"
                                        height="20">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        </bxs-icon>
                                    </template>
                                </headquarter-row>

                                <hr
                                v-if="i < computed_headquarters.length - 1"
                                class="mb-ui">
                            </li>
                        </ul>
                    </bxs-card>
                </bxs-data-lazy>

                <div
                v-if="product && $leila.checkout.shipping.method !== 'delivery'"
                class="mt-ui">
                    <p class="mb-0">Ci {{ product.loan.preparation_days <= 1 ? 'vuole' : 'vogliono' }} <b>{{ product.loan.preparation_days }} gg</b> per preparare l'oggetto per il ritiro<span v-if="$leila.checkout.loan.from_date">, quindi puoi venire a prenderlo <b>a partire dal {{ $filters.toHumanDate($leila.checkout.loan.from_date) }}</b></span><span v-else-if="selected_date.start">, quindi puoi venire a prenderlo <b>a partire dal {{ $filters.toHumanDate(selected_date.start) }}</b></span>.</p>
                </div>
            </bxs-layout>
        </section>

        <!--  -->
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <bxs-title>Hai delle domande?</bxs-title>
                    <p>Specifica tutto in questo spazio, ti risponderemo appena possibile.</p>
                </div>

                <div>
                    <bxs-textarea-field
                    v-model="$leila.checkout.note"
                    solo
                    label="Inserisci qui le tue domande!"
                    name="note" />
                </div>
            </bxs-layout>
        </section>

        <!--  -->
        <section>
            <bxs-layout>
                <bxs-btn
                append-icon="chevron-right"
                between
                block
                light
                color="tertiary"
                uppercase
                min-height="60px"
                class="mb-ui"
                @click="clearCart">Continua con questo prestito</bxs-btn>

                <bxs-list>
                    <bxs-list-item
                    spacer
                    append-icon="chevron-right"
                    @click="clearCart">Abbandona questo prestito e riparti</bxs-list-item>

                    <bxs-list-item
                    to="/help"
                    spacer
                    append-icon="chevron-right">Ne hai bisogno urgente? Contattaci</bxs-list-item>
                    <bxs-list-item
                    spacer
                    append-icon="chevron-right"
                    to="/help"
                    @click="clearCart">Hai bisogno di aiuto? Contattaci</bxs-list-item>
                </bxs-list>
            </bxs-layout>
        </section>

        <!--  -->
        <!-- <pressing-section /> -->

        <!--  -->
        <!-- <section>
            <bxs-layout>
                <bxs-btn
                append-icon="trash"
                between
                block
                light
                color="primary"
                uppercase
                @click="clearCart">
                    <span>Abbandona il checkout e riparti</span>

                    <template #append-icon>
                        <path fill-rule="evenodd" d="M6.146 8.146a.5.5 0 0 1 .708 0L8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 0 1 0-.708"/>
                        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
                    </template>
                </bxs-btn>
            </bxs-layout>
        </section> -->

        <!-- help -->
        <!-- <help-section /> -->

        <!-- checkoutbar -->
        <!-- :disabled="!is_date_selected" -->
        <checkoutbar
        :loading="next_loading"
        @click:next="next" />

        <!-- ------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------- -->
        <bxs-menu
        v-model="shipping.on"
        solo
        :closable="false"
        :close-on-click="false"
        max-width="440">
            <template #header>
                <bxs-title>Indirizzo di consegna</bxs-title>
            </template>

            <div class="pa-layout">
                <!-- <bxs-alert
                v-if="shipping.error"
                type="error"
                class="mb-ui">{{ shipping.error }}</bxs-alert> -->

                <bxs-form
                ref="formAddress"
                @submit="confirmAddress">
                    <!-- <el-autocomplete
                    v-model="shipping.model.geo"
                    :fetch-suggestions="getGeo"
                    value-key="label"
                    placeholder="Città o Codice Postale"
                    clearable
                    size="large"
                    :trigger-on-focus="false"
                    class="mb-ui" /> -->

                    <bxs-text-field
                    v-model="shipping.model.address"
                    name="address"
                    required
                    label="Indirizzo"
                    class="mb-grid-gutter" />

                    <bxs-text-field
                    v-model="shipping.model.cap"
                    name="cap"
                    required
                    label="Codice Postale"
                    class="mb-grid-gutter" />

                    <bxs-text-field
                    v-model="shipping.model.city"
                    name="city"
                    required
                    label="Città"
                    class="mb-grid-gutter" />

                    <bxs-text-field
                    v-model="shipping.model.province"
                    name="province"
                    required
                    label="Provincia"
                    class="mb-grid-gutter" />
                </bxs-form>

                <bxs-alert
                v-if="shipping.error"
                type="error"
                class="mt-ui">{{ shipping.error }}</bxs-alert>
            </div>

            <template #actions>
                <div class="pa-layout">
                    <bxs-btn
                    block
                    color="primary"
                    light
                    :disabled="shipping.loading"
                    :loading="shipping.loading"
                    @click="$refs.formAddress.submit()">Conferma</bxs-btn>

                    <bxs-btn
                    block
                    color="grey"
                    :disabled="shipping.loading"
                    :loading="shipping.loading"
                    class="mt-1"
                    @click="removeDelivery">Annulla e chiudi</bxs-btn>
                </div>
            </template>
        </bxs-menu>
    </div>
</template>

<script>
// import { DatePicker } from 'v-calendar'
// import 'v-calendar/dist/style.css'
import moment from 'moment'
// import { ElAutocomplete } from 'element-plus'

moment.locale('it')

export default {
    name: 'checkout_loan_product',
    components: {
        // DatePicker,
        // 'el-autocomplete': ElAutocomplete
    },
    data () {
        return {
            calendar_loading: true,
            headquarters: [],
            categories: [],
            product: null,
            product_loans: [],
            shipping: {
                on: false,
                error: null,
                loading: false,
                model: {
                    address: null,
                    city: null,
                    province: null,
                    cap: null,
                    geo: null
                }
            },

            // modelConfig: {
            //     type: 'string',
            //     mask: 'DD/MM/YYYY hh:ss' // Uses 'iso' if missing
            // },
            next_loading: false,
            selected_date: {
                start: null,
                end: null
            },
            min_date: null,
            max_date: null,
            disabled_dates: [],
            date_key: 1,
            timezone: null
        }
    },
    computed: {
        is_date_selected () {
            return !!this.selected_date.start && !!this.selected_date.end
        },
        computed_headquarters () {
            let v = this.headquarters

            if (this.product && !this.product.is.tansportable) {
                const h = v.find(i => i.id === this.product.location.address.id)
                if (h) v = [h]
            }

            return v
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            const checkout = await this.$leila.checkout.get()
            this.product = await this.$leila.catalogs.getProduct(checkout.product.id)

            if (checkout.loan.from_date) {
                this.selected_date.start = new Date(checkout.loan.from_date)
            }

            if (checkout.loan.to_date) {
                this.selected_date.end = new Date(checkout.loan.to_date)
            }

            if (this.$store.state.library && this.$store.state.library.delivery.on && this.product.is.tansportable) {
                this.headquarters.unshift({
                    id: 'delivery',
                    name: 'Consegna a domicilio',
                    address: 'Direttamente a casa tua!',
                    img: { url: null, alt: null }
                })
            }

            if (checkout.shipping.method === 'delivery') {
                this.shipping.on = true
            }

            this.shipping.model.city = this.$leila.checkout.shipping.address.city ? this.$leila.checkout.shipping.address.city : this.$leila.me.domicile_geo.city
            this.shipping.model.cap = this.$leila.checkout.shipping.address.cap ? this.$leila.checkout.shipping.address.cap : this.$leila.me.domicile_geo.cap
            this.shipping.model.address = this.$leila.checkout.shipping.address.address && this.$leila.checkout.shipping.address.id === 'delivery' ? this.$leila.checkout.shipping.address.address : this.$leila.me.domicile_geo.address
            this.shipping.model.province = this.$leila.checkout.shipping.address.province ? this.$leila.checkout.shipping.address.province : this.$leila.me.domicile_geo.province

            this.calendar_loading = false
        },
        getGeo (val, cb) {
            this.$leila.geos.list({
                keywords: val
            }).then((docs) => {
                if (cb) cb(docs)
                this.geo = docs
                return docs
            })
        },
        handleInputDate () {
            if (this.selected_date.start && this.selected_date.end) {
                const a = moment(this.selected_date.start).startOf('day')
                const b = moment(this.selected_date.end).startOf('day')
                const days = Math.abs(a.diff(b, 'days')) + 1
                console.log('days', days)

                if (days >= 31) {
                    this.selected_date.start = null
                    this.selected_date.end = null
                    this.$toast.error('Tempo di prestito massimo è di 30 giorni!')
                    this.date_key++
                }
            }
        },
        async setPickup (item) {
            this.next_loading = true
            console.log('setPickup', item)

            try {
                const doc = await this.$leila.checkout.setShippng(
                    item.id === 'delivery' ? 'delivery' : 'pickup',
                    item.id,
                    this.shipping.model
                )

                this.selected_date.start = new Date(doc.loan.from_date)
                this.selected_date.end = new Date(doc.loan.to_date)

                if (item.id === 'delivery') {
                    this.shipping.on = true
                }
            } catch (err) {
                throw new Error(err.message || err.raw || err)
            } finally {
                this.next_loading = false
            }
        },
        async confirmAddress (item) {
            this.shipping.loading = true
            this.shipping.error = null

            try {
                const distance = await this.$leila.get(`v1/geos/${this.product.id}/distances/${this.shipping.model.cap}`)

                if (!distance.length || (distance.length > 0 && distance[0].distance_from_u > this.$store.state.library.delivery.radius)) {
                    this.shipping.error = 'Ci dispiace, sei troppo distante, controlla i campi inseriti o riprova con un altro indirizzo'
                    return this.$toast.warning('Ci dispiace, sei troppo distante, controlla i campi inseriti o riprova con un altro indirizzo')
                }

                const doc = await this.$leila.checkout.setShippng(
                    'delivery',
                    'delivery',
                    this.shipping.model
                )

                this.selected_date.start = new Date(doc.loan.from_date)
                this.selected_date.end = new Date(doc.loan.to_date)

                this.shipping.on = false

                this.$router.push('/checkout/recap')
            } catch (err) {
                if (err.status_code && err.status_code === 404) {
                    this.shipping.error = 'Ci dispiace, CAP non trovato, controlla i campi inseriti o riprova con un altro indirizzo'
                } else {
                    this.shipping.error = 'Ops, ci dispiace, al momento non è possibile prendere in prestito gli oggetti tramite consegna a domicilio a causa di un problema tecnico che verrà risolto nel corso della giornata odierna'
                }
            } finally {
                this.shipping.loading = false
            }
        },
        async removeDelivery () {
            await this.setPickup({ id: this.product.location.id })
            this.shipping.on = false
        },
        async next () {
            if (!this.is_date_selected) {
                return this.$toast.warning('Seleziona la data del prestito!')
            }

            if (this.$leila.checkout.shipping.method === 'delivery' && !this.$leila.checkout.shipping.address.address) {
                this.shipping.on = true
                return this.$toast.warning('Compila il tuo indirizzo per la consegna a domicilio!')
            }

            this.next_loading = true

            try {
                await this.$leila.checkout.setLoanDate(
                    this.selected_date.start,
                    this.selected_date.end,
                    this.$leila.checkout.note
                )
                this.$router.push('/checkout/recap')
            } catch (err) {
                throw new Error(err.message || err.raw || err)
            } finally {
                this.next_loading = false
            }
        },
        async clearCart () {
            this.$store.commit('set_is_loading', true)
            await this.$leila.checkout.clear()
            this.$router.go(-1)
            this.$store.commit('set_is_loading', false)
        }
    }
}
</script>