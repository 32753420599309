<template>
    <div class="search-box">
        <bxs-layout
        max-width="640"
        style="transform: translateY(-48px); z-index: 1;">
            <bxs-card>
                <!-- headers -->
                <bxs-text-field
                v-model="keywords"
                hide-form
                clearable
                :loading="busy"
                name="keywords"
                label="Cerca gli oggetti"
                readonly
                @click="$router.push('/search').catch(() => {})">
                    <template #prepend>
                        <bxs-icon
                        name="search"
                        width="1.25em"
                        class="text-white"></bxs-icon>
                    </template>
                </bxs-text-field>

                <div class="white pa-ui">
                    <bxs-carousel
                    freemode
                    :items="categories"
                    :items-space-between="40">
                        <template #item="{ item }">
                            <div class="flex nowrap column justify-center align-center text-center">
                                <img
                                v-if="item.slug !== 'all'"
                                :src="item.slug === 'in-prestito' ? '/img/categories/in-prestito.svg' : item.img ? $filters.toStaticFile(item.img) : ''"
                                :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} categoria ${item.name}`"
                                style="width: 50px; height: 50px;">

                                <img
                                v-else
                                :src="'/img/tutti-gli-oggetti-icona.png'"
                                alt="Leila biblioteca degli oggetti tutti gli oggetti"
                                style="width: 50px; height: 50px;">

                                <router-link
                                :to="`/catalogs/${item.slug}`"
                                class="text-mute text-400 text-no-deco cat-l">{{ item.name }}</router-link>
                            </div>
                        </template>
                    </bxs-carousel>
                </div>

                <!-- items -->
                <div
                v-if="docs.length > 0"
                class="white pa-ui">
                    <ul>
                        <li
                        v-for="product in docs"
                        :key="product.id"
                        class="bxs-pointer mb-ui">
                            <product-row :item="product" />
                        </li>
                    </ul>
                </div>

                <div
                v-else-if="!!keywords && keywords.length >= 3"
                class="pa-ui mt-ui text-center">
                    <h6>Impossibile trovare</h6>
                    <h6 class="mb-1">"{{ keywords }}"</h6>
                    <p class="text-mute">Prova a effettuare nuovamente la ricerca</p>
                </div>
            </bxs-card>
        </bxs-layout>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import ProductRow from '@/components/project/ProductRow'

export default {
    name: 'search',
    components: {
        'product-row': ProductRow
    },
    data () {
        return {
            keywords: null,
            docs: [],
            busy: false
        }
    },
    computed: {
        ...mapState({
            categories: state => state.products_categories
        })
    },
    watch: {
        keywords () {
            this.getDocs()
        }
    },
    methods: {
        getDocs () {
            if (!this.keywords || this.keywords.length < 3) return

            this.busy = true

            this.$http.get('v1/catalogs/products/search', {
                keywords: this.keywords
            }).then((docs) => {
                this.docs = docs
                this.busy = false
            }).catch(() => {
                this.busy = false
            })
        }
    }
}
</script>